import { Script } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';

interface CaptchaVerificationProps {
  onSuccess: (token: string) => void;
  resetCaptcha?: boolean;
}

const CaptchaVerification = ({
  onSuccess,
  resetCaptcha,
}: CaptchaVerificationProps) => {
  const captchaRef = useRef(null);

  globalThis.onloadCaptchaCallback = function () {
    globalThis.turnstile.render(captchaRef.current, {
      sitekey: process.env.RECAPTCHA_SITE_KEY || 'site-key',
      callback: function (token: string) {
        onSuccess(token);
      },
    });
  };

  useEffect(() => {
    if (resetCaptcha) {
      globalThis.turnstile.reset(captchaRef.current);
    }
  }, [resetCaptcha]);

  const scriptPath =
    'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit&onload=onloadCaptchaCallback';

  return (
    <>
      <Script src={scriptPath} async />
      <div ref={captchaRef} />
    </>
  );
};

export default CaptchaVerification;
