import React from 'react';

import Container from '../Container';
interface FeatureCardProps {
  image: React.ReactNode;
  content: string;
  title: string;
}

const FeatureCard = (props: FeatureCardProps) => {
  const { image, title, content } = props;

  return (
    <Container className="card zoom space-y-4">
      <div className="flex items-center space-x-4">
        <div>{props.image}</div>
        <h2 className="tagline font-[500] text-foreground">{title}</h2>
      </div>
      <div>
        <div>
          <p>{content}</p>
        </div>
      </div>
    </Container>
  );
};

export default FeatureCard;
