import React from 'react';

import Container from '../Container';

interface TextField {
  label: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  onBlur?: () => void;
  helperText?: string;
  placeholder?: string;
}

const TextField = (props: TextField) => {
  const { label, onChange, id } = props;
  return (
    <Container className="form-field">
      <label htmlFor={id} className="label">
        {label}
      </label>
      <div>
        <input
          id={id}
          name={id}
          value={props.value}
          type="text"
          className={`form-control ${props.helperText ? 'error' : ''}`}
          onChange={onChange}
          onBlur={props.onBlur}
          placeholder={props.placeholder}
        />
        {props.helperText && (
          <div className="text-error text-[1rem] mt-1">{props.helperText}</div>
        )}
      </div>
    </Container>
  );
};

export default TextField;
