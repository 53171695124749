import React from 'react';
import cx from 'classnames';

import Container from '../Container';

interface ButtonProps {
  type?: 'submit' | 'button' | 'reset';
  variant?: 'primary' | 'default' | 'outlined';
  disabled?: boolean;
  label: string;
}

const Button = (props: ButtonProps) => {
  return (
    <Container>
      <button
        type={props.type ? props.type : 'button'}
        disabled={props.disabled}
        className={cx({
          'px-2 py-1 rounded-lg border-1': true,
          'bg-foreground text-primary hover:opacity-90  font-bold':
            props.variant === 'primary',
          'text-gray hover:text-foreground': props.variant === 'default',
        })}
      >
        {props.label}
      </button>
    </Container>
  );
};

export default Button;
