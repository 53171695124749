import React from 'react';
import Container from '../Container';

interface TextAreaProps {
  id: string;
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea = (props: TextAreaProps) => {
  return (
    <Container className="form-field">
      <label htmlFor={props.id} className="label">
        {props.label}
      </label>
      <textarea
        id={props.id}
        name={props.id}
        value={props.value}
        rows={4}
        className="form-control"
        onChange={props.onChange}
      />
    </Container>
  );
};

export default TextArea;
