import React, { useRef } from 'react';

import FeatureCard from '../FeatureCard';
import { useActivePath, useSiteMetadata } from '../../hooks';
import Container from '../Container';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import { FileSystemNode } from 'gatsby-source-filesystem';
import SimpleIcon from '../../icons/simple.svg';
import SecureIcon from '../../icons/secure.svg';
import AfforabilityIcon from '../../icons/affordable.svg';
import ResilientIcon from '../../icons/resilient.svg';
import CTA from '../CTA';

const Feature = () => {
  const imagesData = useStaticQuery(graphql`
    query imagesQuery {
      allFile(
        filter: {
          relativePath: {
            in: ["secure.png", "affordable.png", "resilient.png", "simple.png"]
          }
        }
      ) {
        nodes {
          name
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `);

  const imageMap: Record<string, IGatsbyImageData | undefined> = {};
  imagesData.allFile.nodes.forEach((f: FileSystemNode) => {
    imageMap[f.name] = getImage(f);
  });
  const ref = useRef(null);
  const data = useSiteMetadata();
  useActivePath('/#features', ref);
  const featuresMap = [
    {
      id: 'simple',
      image: <SimpleIcon className="w-8 h-8 stroke-foreground" />,
      title: 'Simple',
      content:
        'Discover simplicity. Create production-grade Kafka clusters within minutes.',
    },
    {
      id: 'affordable',
      image: <AfforabilityIcon className="w-8 h-8 stroke-foreground" />,
      title: 'Affordable',
      content:
        'Escape high cloud costs. Deploy your Kafka for as low as $16/month.',
    },
    {
      id: 'resilient',
      image: <ResilientIcon className="w-8 h-8 stroke-foreground" />,
      title: 'Resilient',
      content:
        'Experience high resilence. Operate your Kafka instances with continuous monitoring, self-healing and elastic scalabilty.',
    },
    {
      id: 'secure',
      image: <SecureIcon className="w-8 h-8 stroke-foreground" />,
      title: 'Secure',
      content:
        'Rest easy with enterprise-grade security protection. Experience continuous thread detection, runtime monitoring and alerting.',
    },
  ];

  return (
    <section id="features" className="section scroll-mt-16" ref={ref}>
      <Container>
        <h1 className="text-center">The Bitnimbus.io Platform</h1>
        <p className="tagline text-center my-6">
          Empowers Developers to Build World-class Software Products
        </p>
        <div className="content-spacing grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 auto-rows-[1fr]">
          {featuresMap.map((feature, index) => (
            <FeatureCard {...feature} />
          ))}
        </div>
        <div className="my-8 flex items-center justify-center">
          <CTA
            type="primary"
            label="Explore the Product"
            external={true}
            link={data.site.siteMetadata.productLink}
          />
        </div>
      </Container>
    </section>
  );
};

export default Feature;
